import React from 'react';
import Layout from '../../components/shared/Layout';
import SEO from '../../components/shared/SEO';
import AdditionalInformation
    from "../../components/shared/additionalInformation/additionalInformation";
import Footer from "../../components/shared/Footer/Footer";
import ElectronicDcodeGenerator from "../../components/sections/ElectronicDcodeGenerator/ElectronicDcodeGenerator";
import {getRoutes} from "../../components/shared/routes";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import BackToTechnologyButton from "../../components/shared/ButtonBackTo/BackToTechnologyButton";

const Edg = () => {
    const routes = getRoutes();
    const parentTitle = routes.Technology.pageTitle;
    const title = routes.TechnologyEDG.pageTitle;

    return (
        <Layout>
            <SEO title={title}/>

            <Breadcrumbs
                title={title}
                parentTitles={[parentTitle]}
                parentRoutes={[routes.Technology]}
            />

            <ElectronicDcodeGenerator/>

            <BackToTechnologyButton/>

            <AdditionalInformation/>

            <Footer/>
        </Layout>
    );
};

export default Edg;
